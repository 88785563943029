<template>

  <div class="page-cu-container">
    <div class="page-cu-main">
      <a-row type="flex" class="page-cu-row">
        <a-col :span="24" class="page-cu-left">
          <div class="page-cu-left-head">
            <a-form layout="inline">
              <a-form-item>
                <a-button type="primary" @click="addAction" icon="plus">添加App版本</a-button>
              </a-form-item>
            </a-form>
          </div>
          <div class="page-cu-left-table">
            <vuescroll>
              <a-table :columns="columns" :data-source="list" rowKey="id" bordered :pagination="false">
                <template slot="create_time" slot-scope="text, record">
                  <span>{{ text.substr(0, 16) }}</span>
                </template>
                <template slot="type" slot-scope="text, record">
                  <span v-if="text == '1'">安卓</span>
                  <span v-else>苹果</span>
                </template>
                <template slot="is_forcibly" slot-scope="text, record">
                  <span v-if="text == '1'">否</span>
                  <span v-else>是</span>
                </template>
                <template slot="create_time" slot-scope="text, record">
                  <span>{{ text.substr(0, 16) }}</span>
                </template>
                <template slot="action" slot-scope="text, record">
                  <a-button type="link" @click="openEditDialog(record.id)" icon="edit">编辑</a-button>
                  <a-button type="link" @click="delConfirm(record.id)" icon="delete">删除</a-button>
                </template>

              </a-table>
              <div class="page-cu-left-page">
                <a-pagination
                  v-model="page.start"
                  :page-size.sync="page.pageSize"
                  :total="page.totalRow"
                  @change="changePage"
                />
              </div>
            </vuescroll>
          </div>
        </a-col>
      </a-row>
    </div>
    <a-modal title="App版本管理" :width="800" :destroyOnClose="true" :confirmLoading="$store.state.requestLoading"
             :visible="actionVisible" @ok="actionData" ok-text="确认" cancel-text="取消" @cancel="cancel">
      <a-form-model ref="formModel" :rules="formModelRules" :model="formModel" layout="vertical"
                    :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
        <a-form-model-item prop="internal_version" label="内部版本号">
          <a-input v-model="formModel.internal_version" type="number" placeholder="请输入内部版本号" />
        </a-form-model-item>
        <a-form-model-item prop="external_version" label="外部版本号">
          <a-input v-model="formModel.external_version" placeholder="请输入外部版本号" />
        </a-form-model-item>
        <a-form-model-item prop="type" label="客户端">
          <a-radio-group v-model="formModel.type">
            <a-radio value="1">安卓</a-radio>
            <a-radio value="2">苹果</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item prop="is_forcibly" label="强制更新">
          <a-radio-group v-model="formModel.is_forcibly">
            <a-radio value="1">否</a-radio>
            <a-radio value="2">是</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item prop="android_down_url" label="下载地址" v-if="formModel.type == '1'">
          <a-textarea :rows="3" v-model="formModel.android_down_url" placeholder="请输入下载地址" />
        </a-form-model-item>
        <a-form-model-item prop="content" label="更新内容">
          <a-textarea :rows="3" v-model="formModel.content" placeholder="请输入更新内容" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
  import * as Api from './api';
  import axios from 'axios';

  export default {
    name: "index",
    data() {
      return {
        formModel: {
          internal_version: '',
          external_version: '',
          type: '',
          content: '',
          is_forcibly: '',
          android_down_url: ''
        },
        formModelRules: {
          internal_version: [
            { required: true, message: "请输入内部显示版本号", trigger: "change" },
          ],
          external_version: [
            { required: true, message: "请输入外部显示版本号", trigger: "change" },
          ],
          type: [
            { required: true, message: "请选择客户端", trigger: "change" },
          ],
          is_forcibly: [
            { required: true, message: "请选择是否强制更新", trigger: "change" },
          ]
        },
        columns: [
          {
            title: "内部版本号",
            dataIndex: "internal_version",
          },
          {
            title: "外部版本号",
            dataIndex: "external_version",
          },
          {
            title: "客户端",
            dataIndex: "type",
            scopedSlots: { customRender: "type" },
          },
          {
            title: "强制更新",
            dataIndex: "is_forcibly",
            scopedSlots: { customRender: "is_forcibly" },
          },
          {
            title: "添加时间",
            dataIndex: "create_time",
            scopedSlots: { customRender: "create_time" },
            width:200
          },
          {
            title: "操作",
            dataIndex: "action",
            key: "action",
            width: 200,
            scopedSlots: { customRender: "action" }
          }
        ],
        page: {
          start: 1,
          totalRow: 0,
          pageSize: 0,
          limit: 20
        },
        list: [],
        fileList: [],
        actionVisible: false,
      }
    },
    mounted() {
      this.getList();

    },
    methods: {
      /**
       * 图片上传完成的事件
       * @author wheat
       * */
      changeImgUrl(url) {
        this.formModel.img_url = url;
      },
      /**
       * 添加，更新
       * @author wheat
       */
      actionData() {
        this.$refs.formModel.validate(async valid => {
          if (valid) {
            let res = null;
            if (this.formModel['id']) {
              res = await Api.Update(this.formModel);
            } else {
              res = await Api.Save(this.formModel);
            }

            if (res && res['code'] == '0') {
              this.$message.success(res.message);
              this.actionVisible = false;
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          }
        });
      },
      /**
      * 表单重置
      * @author
      */
      resetFormModel() {
        for (let key in this.formModel) {
          this.formModel[key] = '';
        }
      },
      /**
       * 打开窗口
       * @author wheat
       * */
      addAction() {
        this.resetFormModel();
        this.actionVisible = true;
      },
      /**
       * 关闭穿口
       * @author wheat
       * */
      cancel() {
        this.actionVisible = false;
      },

      /**
       * 删除
       * @author wheat
       * */
      async delConfirm(id) {
        let that = this;
        this.$confirm({
          title: '信息提示',
          okType: 'danger',
          content: '您确定要删除吗?',
          async onOk() {
            let res = await Api.Delete({id: id})
            if (res && res.code == '0') {
              that.$message.success(res.message);
              that.getList();
            } else {
              that.$message.error(res.message);
            }
          },
          onCancel() {
          },
        });
      },
      /**
       * 推荐/取消到首页
       * @author wheat
       * */
      recommendConfirm(record) {
        let that = this;
        this.$confirm({
          title: '信息提示',
          okType: 'danger',
          content: record.is_index == '1' ? '您确定要推荐到首页吗?' : '你确定要取消推荐到首页吗?',
          async onOk() {
            let res = await Api.ChangeIndex({id: record.id, is_index: record.is_index == '1' ? '2' : '1'})
            if (res && res.code == '0') {
              that.$message.success(res.message);
              that.getList();
            } else {
              that.$message.error(res.message);
            }
          },
          onCancel() {
          },
        });
      },
      /**
       * 获取列表
       * @author wheat
       * */
      async getList() {
        let res = await Api.List({
          start: this.page.start,
          limit: this.page.limit,
        })
        this.page.totalRow = res.page.totalRow;
        this.page.pageSize = res.page.pageSize;
        this.list = res.page.list;
      },
      /**
       * 分页切换
       * @author wheat
       * */
      changePage(currentPage) {
        this.page.start = currentPage;
        this.getList();
      },
      /**
       * 获取编辑内容
       * @author wheat
       * */
      async openEditDialog(id) {
        this.resetFormModel()
        let res = await Api.Detail({id: id});
        if (res && res['code'] == '0') {
          this.formModel = Object.assign({}, res['target']);
          this.actionVisible = true;
        }
        this.$message.success(res.message);
      },
    },
  }
</script>

<style scoped>

</style>
